<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.lnb.common_2depth_member_management'),
        url: 'members'
      },
      {
        name: $t('studio.member_manage.role_manage_btn')
      }
    ]"
    :title="$t('studio.member_manage.role_manage_btn')"
  >
    <s-button variant="primary" size="md" @click="() => handleViewRole()">
      {{ $t('studio.member_manage.role_manage_create_role_btn') }}
    </s-button>
  </st-title>
  <div class="mt-40">
    <div class="grid grid-cols-3 gap-24">
      <st-home-box
        v-for="role in roleList?.contents.filter((role) => role.roleName !== ROLE_OWNER)"
        :key="role.roleNo"
        class="!h-[26rem] col-span-1 hover:-translate-y-4 hover:shadow-[0_.8rem_2rem_0_rgba(0,51,150,.16)] transition-all duration-300 ease-in-out"
        catchHover
        useLink
        @click="handleViewRole(role.roleNo)"
      >
        <role-card
          class="cursor-pointer"
          :roleName="$t(getRoleName(role.roleName))"
          :roleDate="
            role.modifiedAt
              ? getDateTimeByLocale(role.modifiedAt)
              : role.createdAt
                ? getDateTimeByLocale(role.createdAt)
                : '-'
          "
          :adminButton="getBoolean(role.editableYn)"
        />
      </st-home-box>
    </div>
    <s-pagination
      v-if="roleList && roleList.totalElements > 11"
      v-model="renderedPage"
      :pageCount="roleList?.totalPages ?? 0"
      displayFirstAndLastButton
      class="!py-32"
      @change="onPageChange"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import { definePageMeta } from '@/.nuxt/imports';
import StHomeBox from '@/components/common/st-home-box.vue';
import StTitle from '@/components/common/st-title.vue';
import RoleCard from '@/components/member-mng/role-card.vue';
import { MODE } from '@/constants/common.const';
import { ROLE_OWNER } from '@/constants/permission.const';
import { MEMBER_ROLES_PAGE_URL } from '@/constants/url.const';
import { useRoleStore } from '@/stores/role.store';
import { useUserStore } from '@/stores/user.store';
import { getBoolean, redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { getRoleName } from '@/utils/permission.util';

definePageMeta({
  middleware: 'check-menu-writable-middleware'
});

const userStore = useUserStore();
const roleStore = useRoleStore();

const { selectedGroupId } = storeToRefs(userStore);
const { roleList } = storeToRefs(roleStore);

const currentPage = ref<number>(1);
const renderedPage = ref<number>(1);

const handleViewRole = (roleId?: number) => {
  const id = `?roleId=${roleId}`;

  redirectTo(`${MEMBER_ROLES_PAGE_URL}/${roleId ? MODE.EDIT : MODE.CREATE}${roleId ? id : ''}`);
};

const onPageChange = async (page: number) => {
  if (page === currentPage.value) {
    return;
  }
  currentPage.value = page;
  await roleStore.fetchRoleList(selectedGroupId.value, {
    page
  });
};

const init = async () => {
  try {
    await roleStore.fetchRoleList(selectedGroupId.value);
  } catch (error) {}
};

init();
</script>
