<!-- HomeBoxComponent.vue -->
<template>
  <div
    class="relative h-[30.4rem] rounded-[4rem] p-[3rem] shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] flex flex-col"
    :class="chartType ? 'bg-brand-primary' : 'bg-surface-elevation-1'"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div v-if="dragUse" class="absolute inset-0 z-[3]">
      <input
        :id="id"
        class="studio-home-checkbox peer absolute -top-4 -left-4"
        type="checkbox"
        :name="name"
        :disabled="isDisabled"
        :checked="isChecked"
      />
      <label
        class="block w-full h-full bg-[rgba(255,255,255,.85)] border-[.2rem] border-solid cursor-pointer rounded-[4rem] peer-checked:bg-transparent peer-disabled:cursor-default"
        :class="drag ? 'border-brand-primary' : 'border-transparent'"
        :for="id"
      >
        <span class="sr-only">check</span>
      </label>
    </div>
    <div
      v-if="catchHover"
      class="relative h-full flex flex-col"
      :class="isHovered ? 'hasHover' : ''"
    >
      <slot></slot>
    </div>
    <template v-else><slot></slot></template>
    <a v-if="useLink" href="" class="absolute inset-0" @click.prevent=""></a>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  chartType?: boolean;
  id?: string;
  name?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  dragUse?: boolean;
  drag?: boolean;
  useLink?: boolean;
  catchHover?: boolean;
}>();

const isHovered = ref(false);
</script>
